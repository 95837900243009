body {
  position: relative;
  font-family: "Open Sans" !important;
  font-size: 14px;
  background-color: #f7f7f7 !important;
  color: #000
}

@font-face {
  font-family: 'Open sans';
  src: url(fonts/OpenSans.ttf);
}
@keyframes zoomin {
  0%{
    transform : scale(0.3)
  }

  100%{
    transform : scale(1)
  }
  
}

.zoom {
  animation: zoomIn 4s;
}

.elementor-background-overlay {
  background-color: #000;
  opacity: .25;
  transition: border-radius .3s, opacity .3s;
  height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: 500;
}

.halfWidth{
  width: 47% !important
}

.fullWidth{
  width: 100% !important
}

.rich-text{

    padding: 0 9%;

}
.landingCarouselCaption{
  z-index: 21
}

.whiteText{
  color:white !important;
  font-size: 10px 
}

.noMargin{
  margin: 0 !important
}

h1{
  font-weight: 700 !important;
  color:black !important;
}

h2{
  font-weight: 700 !important;
  color:black !important;
}

h5{
  font-weight: 700 !important;
  color:black !important;
}

.paddingOff{
  padding:0px !important;
}

.card-static{
  background: none !important
}

.card-static1{
  background: none !important
}
.noBackground{
  background: transparent !important;
}

.marginOff{
  margin: 0px !important;
}

.paddingBottom{
    padding-bottom: 2%;
}

.webHeight{
  height:-webkit-fill-available; 
  width: -webkit-fill-available;
}

.redText{
  color: #ed1b2e !important;
}

.lightFont{
  font-weight: 100 !important;
}

.heavyFont{
  font-weight: 700 !important;
}

.shadow{
  box-shadow: 0 2.6rem 3rem rgba(0,0,0,.05)
}

.whiteBg{
  background-color: white;
}

.max-width{
  max-width: 1366px; 
    margin: 0 auto;
}

.inlineDisplay{
  display: flex;
}

.inlineMargin{
  margin-right: 3%;
  margin-bottom: 3%
}

.clearSpaceMini{
  margin-bottom: 3% !important
}


.carouselCard{
  width:22rem !important;
  height: 400px !important
}

.nav-link.active{
  background-color: transparent !important;
}

footer {
  overflow-x: hidden;
}

.accordion-button:not(.collapsed) {
  background-color: var(--bs-accordion-active-bg);
}

/* navbar style start */ 

.navHeight{
  height:16%
}

.mag {
  width: 40px;
  height: 25px;
}
.msg {
  width: 40px;
  height: 25px;
}
.crd {
  width: 40px;
  height: 25px;
}


a {
  /**padding-left: 10px;**/
  text-decoration: none;
  color: #6c7a8a;
}

.indv {
  background-color: white;
  border-bottom-left-radius: 50px;
  border-top-left-radius: 50px;
  padding: 2px 10px 7px 15px;
}

button {
  border: 2px solid #ececf1;
  border-radius: 50px;
  background-color: white;
  padding: 3px 20px 3px 20px;
  margin-left: 20px;
}

li {
  list-style: none;
  color: white;
}

.low-nav {
  background-color: #ed1b2e;
}

.accordion {
   --bs-accordion-border-color: transparent !important;
   width:100%
}
/* navbar style end */ 



/* individual page style start */ 
.landingCarousel{
  height: 80vh !important;
}


.landingCarouselCaption{
  background-color: white;
  padding: 3.2rem 4rem;
  border-radius: 0.8rem;
  width:35%;
  color: black !important;
  text-align: left !important;
  bottom: -13% !important;
  z-index: 530;
  box-shadow: 0 2.6rem 3rem rgba(0,0,0,.05)
}



.carousel-inner{
  overflow: visible !important;
  height: 80vh !important;
}
.bannerText{
  font-size: 48px;
  font-weight: 700;
}

.bannerText2{
  font-size: 40px;
  font-weight: 700;
}

.cta-primary {
  position: relative;
  display: inline-block;
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  line-height: 2.8rem;
  text-align: center;
  background-color: transparent;
  text-decoration: none;
  border: none;
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}

.cta-primary span {
  display: inline-block;
  padding: 0.5rem 2.2rem 0.6rem;
  background-color: #ed1b2e;
  border-radius: 10rem;
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}

.clearSpaceBottom{
  margin-bottom: 22%;
}

.clearSpaceBottom1{
  margin-bottom: 8%;
}

.test{ 
  height: 300px;
  background-color: black;
}

.prulife::before{
    content: "";
    position: absolute;
    left: 10;
    bottom: 3.2rem;
    background-color: #e5eaef;
    width: 70%;
    max-width: 28.6rem;
    height: 32%;
    z-index: -1;

}


.sectionText{
  font-size: 60px;
  font-weight:400
}

.section {
  max-width: 1366px;
  margin: 0 auto;
}

.basic-panel{
  overflow: hidden ;
}

.basic-panel__wrapper {
  position: relative;
}

.basic-panel__wrapper::before {
  content: "";
  position: absolute;
  background-color: #e5eaef;
  z-index: -1;
}

.basic-panel__wrapper .container{
  position: relative;
}

.pd-100-60 {
  padding-bottom: 7rem;
}


.basic-panel.promotion .basic-panel__outer--img img {
  position: absolute;
  width: 100%;
  height: 100%;
}

.basic-panel.promotion .basic-panel__outer::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 3.2rem;
  background-color: #e5eaef;
  width: 70%;
  max-width: 46.6rem;
  height: 100%;
  z-index: -1;
}

.basic-panel.promotion .basic-panel__outer1::before {
  content: "";
  position: absolute;
  left: 27.7%;
  bottom: 3.2rem;
  background-color: #e5eaef;
  width: 70%;
  max-width: 46.6rem;
  height: 100%;
  z-index: -1;
}

.basic-panel.promotion .basic-panel__outer--img {
  position: relative;
  width: 100%;
  padding-bottom: 66.66%;
  overflow: hidden;
}


.component-rich-text, .floating-cta__content {
  position: relative;
  overflow: hidden;
}

.component-rich-text__wrapper {
  position: relative;
  padding-top: 3.2rem;
  margin-top: 2rem;
}

.component-rich-text .bk-blue-gray {
  position: absolute;
  width: 200%;
  left: 8.2rem;
  top: 0;
  bottom: 6rem;
  background: #e5eaef;
  z-index: -1;
}


.component-rich-text .bk-blue-gray-intro {
  position: absolute;
  width: 200%;
  left:0;
  top: 0;
  bottom: 6rem;
  background: #e5eaef;
  z-index: -1;
}

.component-rich-text .bk-blue-gray1 {
  position: absolute;
  width: 200%;
  left:0;
  top: 0;
  bottom: 6rem;
  background: #e5eaef;
  z-index: -1;
}

.component-rich-text .bk-blue-gray2 {
  position: absolute;
  width: 200%;
  left:-2rem;
  top: 0;
  bottom: 6rem;
  background: #e5eaef;
  z-index: -1;
}

.component-rich-text .bk-blue-gray3 {
  position: absolute;
  width: 200%;
  left:-2rem;
  top: 0;
  bottom: -3rem;
  background: #e5eaef;
  z-index: -1;
}

.component-rich-text .bk-blue-gray4 {
  position: absolute;
  width: 200%;
  left: 8.2rem;
  top: 0;
  bottom: 6rem;
  background: #e5eaef;
  z-index: -1;
}


.component-rich-text .bk-blue-gray5 {
  position: absolute;
  width: 200%;
  left: 8.2rem;
  top: -19px;
  bottom: 6rem;
  background: #e5eaef;
  z-index: -1;
}



.container-rich-text {
  padding: 3.2rem 0;
  border-radius: .8rem;
  background: #fff;
}

.rich-text .editorial-body-copy {
  font-size: 18px;
  line-height: 30px;
  letter-spacing: 0;
  font-weight: 400;
  font-style: normal;
  color: #000;
  margin-bottom: 2rem;
}

.rich-text .editorial-body-copy {
  font-size: 20px;
  line-height: 35px;
  margin-bottom: 3.2rem;
}


.card-static {
  position: relative !important;
  border: none !important;
  background: #fff !important;
  box-shadow: 0 2.6rem 3rem rgba(0, 0, 0, .05) !important;
  border-radius: .8rem !important;
  color: #000 !important;
  display: block !important;
  height: 470px !important;
}

.card-static1 {
  position: relative !important;
  border: none !important;
  background: #fff !important;
  box-shadow: 0 2.6rem 3rem rgba(0, 0, 0, .05) !important;
  border-radius: .8rem !important;
  color: #000 !important;
  display: block !important;
}

.card-static__content {
  padding: 1.6rem 1.6rem 3.2rem !important;
}

.link-icon .card-static__content {
  padding-bottom: 5.2rem !important;
}

.border-radius-card{
  border-top-left-radius: 0.8rem;
  border-top-right-radius: 0.8rem;
}

.border-radius-card1{
  border-top-left-radius: 0.8rem;
  border-bottom-left-radius: 0.8rem;
}

.card-static__content--title {
  font-weight: 700;
  font-size: 25px;
  line-height: 1.8rem;
}

.card-static__content--desc {
  margin-top: 1.2rem;
  font-size: 16px;
  line-height: 2.4rem;
  font-weight: 300;
}

.card-static__content--btn.btn-arrow,.card-static__content--btn.btn-arrow1 {
  position: absolute;
  right: 2.2rem;
  bottom: -1rem;
}



.card-static__content--btn {
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
}

.input-text-field input[type=email].field-input:focus~label, .input-text-field input[type=email].field-input:not(:placeholder-shown)~label, .input-text-field input[type=number]:focus~label, .input-text-field input[type=number]:not(:placeholder-shown)~label, .input-text-field input[type=text]:focus~label, .input-text-field input[type=text]:not(:placeholder-shown)~label, .input-text-field input[type=date].field-input:focus~label, .input-text-field input[type=date].field-input:not(:placeholder-shown)~label, .input-text-field select.field-input:focus~label, .input-text-field select.field-input:not(:placeholder-shown)~label {
  color: #000;
  font-size: 14px;
  font-weight: 700;
  line-height: 2rem;
  top: 1rem;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.btn-arrow {
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 40px;
  height: 40px;
  font-size: 14px;
  line-height: 1.4rem;
  border-radius: 50%;
  background-color: #ed1b2e;
  color: #fff;
  overflow: hidden;
}

.btn-arrow1 {
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 40px;
  height: 40px;
  font-size: 14px;
  line-height: 1.4rem;
  border-radius: 50%;
  background-color: #ed1b2e;
  color: #fff;
  overflow: hidden;
}

.btn-arrow .icon{
  position: relative;
}

[class*=" icon-"], [class^=icon-] {
  font-family: icomoon !important;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



.product-owl .owl-stage-outer {
  position: relative;
  height: 80vh ;
  padding-left: 6%;
  padding-right: 6%
}


.blog-owl .owl-stage-outer {
  position: relative;
  height: 84vh;
  padding-right: 6%;
  padding-left: 6%;
}



.owl-item{
  width: fit-content !important
}

.owl-carousel.owl-loaded {
  display: block;
  overflow: auto;
}

.basic-panel__content--title {
  font-size: 36px;
  line-height: 4.8rem;
  font-weight: 700;
  color: #000;
}

.link{
  text-decoration: none !important;
  color: black !important
}
.link1{
  text-decoration: none !important;
  color: white !important
}

.btn-link {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  font-size: 20px;
  font-weight: 600;
  line-height: 2.8rem;
  padding-right: 3rem;
}

.textGroupPosition{
  
    position: relative;
    top: 8%;

}

.filter-tab-item {
  display: inline-block;
  padding: 1.4rem 2rem;
  margin-left: 1rem;
  font-style: italic;
  font-size: 15px;
  line-height: 1.6rem;
  color: #68737a !important;
  background: #fff;
  border-radius: 10rem;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  cursor: pointer;
}

.tabs-btn{
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  font-style: italic !important;
  color: #68737a !important;
}

/** corporate page start **/ 

.page-title {
  position: relative;
}

.page-title__page-wrapper {
  position: relative;
}

.page-title__content-outer {
  padding-top: 3.6rem;
  padding-bottom: 5rem;
}

.page-title__content--name {
  font-size: 36px;
  line-height: 48px;
  font-weight: 700;
}

.page-title__content--desc {
  font-size: 18px;
  line-height: 28px;
  margin-top: 1.6rem;
  font-weight: 400;
}

.color-red {
  color: #ed1b2e;
}

.page-title__content--name .color-red {
  font-weight: 300;
}

.page-title__img-outer::before {
  content: '';
  position: absolute;
  top: 0;
  right: 15px;
  width: 56.1%;
  min-height: 13.6rem;
  background-color: #e5eaef;
  z-index: 0;
}

.page-title__img-outer1::before {
  content: '';
  position: absolute;
  top: 0;
  right: 15px;
  width: 56.1%;
  min-height: 13.6rem;
  background-color: #e5eaef;
  z-index: 0;
}


/** Policy landing Page **/ 

.accordion-comp {
  margin-bottom: 1.2rem;
  border-radius: .8rem !important;
  background: #fff;
}

.accordion-comp-header {
  position: relative;
  padding: 2rem 7rem 2rem 2.4rem;
  cursor: pointer;
}

.accordion-comp-title {
  font-size: 1.8rem;
  line-height: 2.8rem;
  font-weight: 700;
  color: #000;
}


.title-form {
  margin-bottom: 1rem;
  font-weight: 800;
  font-size: 36px;
  line-height: 2.8rem;
  color: black;
}

.contact-form .mandatory-text {
  margin-bottom: 2rem;
  font-weight: 300;
  font-size: 1.2rem;
  line-height: 2.4rem;
  color: #ed1b2e;
}

.section-radio-checkbox {
  padding: 0;
  margin-bottom: 3.2rem;
  border: none;
}


fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

.field-item {
  margin-bottom: 3rem;
}

.group-field__title {
  margin-bottom: 1rem;
  font-size: 18px;
  line-height: 2.8rem;
  color: #000;
}

.radio-checkbox__wrapper {
  display: -webkit-box;
  display: flex;
  margin-bottom: 0;
  position: relative;
}

.radio-checkbox__wrapper .radio-checkbox {
  margin-right: 4rem;
  cursor: pointer;
}

.radio-checkbox__wrapper .radio-checkbox__label {
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 800;
  line-height: 2.8rem;
  text-transform: capitalize;
  color:black
}


.radio-checkbox__wrapper .radio-checkbox__input {
  opacity: 0;
}

input[type=radio] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

.radio-checkbox__wrapper .radio-checkbox span {
  position: relative;
  display: inline-block;
  margin: .5rem 1.2rem 0 -1.3rem;
  vertical-align: top;
  width: 1.7rem;
  height: 1.7rem;
  border-radius: 50%;
  border: 2px solid rgba(0, 0, 0, .2);
  background: #fff;
  pointer-events: none;
}

.radio-checkbox__wrapper .radio-checkbox input[type=radio]:checked+span:before {
  opacity: 1;
  background-color: #ed1b2e;
}
.radio-checkbox__wrapper .radio-checkbox span::before {
  content: "";
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
  margin: 50% auto 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: center;
  vertical-align: middle;
}


.group-field__title[data-required]:after {
  content: " *";
  display: inline-block;
  color: #ed1b2e;
  margin-left: .5rem;
}

.input-text-field .field-item {
  position: relative;
  border: none;
  margin-bottom: 2rem;
  width: 100%;
  -webkit-box-shadow: 0 2.6rem 3rem rgba(0, 0, 0, .05);
  box-shadow: 0 2.6rem 3rem rgba(0, 0, 0, .05);
  border-radius: 8px;
  background-color: #fff;
}

.input-text-field input[type=text], .input-text-field input[type=email], .input-text-field input[type=number], .input-text-field input[type=date], .input-text-field select {
  color: #000;
  background-color: transparent;
  border: none;
  caret-color: #68737a;
  font-size: 18px;
  line-height: 1.8rem;
  padding: 2.2rem 2rem 1rem;
  font-weight: 300;
  display: block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  bottom: 0;
}
.input-text-field .field-item .field-input {
  border-radius: 8px;
}

.input-text-field .field-placeholder {
  position: absolute;
  top: 50%;
  margin: 0;
  padding: 0 2rem;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  pointer-events: none;
  -webkit-transition: all .14s ease-in-out;
  transition: all .14s ease-in-out;
  font-size: 1.8rem;
  line-height: 2.8rem;
  font-weight: 300;
  color: #000;
}

.input-text-field input[type=text][required]~label:after {
  content: "*";
  display: inline-block;
  color: #ed1b2e;
  margin-left: .5rem;
}

.input-text-field input[type=email].field-input:focus~label{
  color: #000;
  font-size: 14px;
  font-weight: 700;
  line-height: 2rem;
  top: 1rem;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.input-text-field input[type=text].field-input:focus~label{
  color: #000;
  font-size: 14px;
  font-weight: 700;
  line-height: 2rem;
  top: 1rem;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.input-text-field input[type=number].field-input:focus~label{
  color: #000;
  font-size: 14px;
  font-weight: 700;
  line-height: 2rem;
  top: 1rem;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

label {
  display: inline-block;
  margin-bottom: .5rem;
}


.input-text-field input[type=email].field-input:focus, .input-text-field input[type=number]:focus, .input-text-field input[type=text]:focus {
  outline: 0;
}


/** contact page **/ 

#enquiry{
  height : 200px !important
}

.standardPadding{
  padding: 3% !important
}

/** glossary page **/
.tableStyle{
  border: 1px solid grey;
  border-top-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent; 
  padding-top: 3%;
  padding-bottom:3%
}

.tableDataStyle{
  padding-top: 3%;
  padding-bottom:3%
}

/** about **/ 
.basic-panel.info .basic-panel__outer--img {
  position: relative;
  width: 100%;
  padding-bottom: 133.333%;
  overflow: hidden;
  margin-bottom: 0;
  z-index: 1;
}

.basic-panel.info .basic-panel__outer img {
  position: absolute;
  width: 100%;
  max-width: 100%;
  height: auto;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.basic-panel.info .basic-panel__content {
  position: relative;
  z-index: 2;
}

.basic-panel.info .basic-panel__content--wrapper {
  background-color: #fff;
  padding: 3.2rem 2.8rem;
  border-radius: 0 .8rem .8rem 0;
  -webkit-box-shadow: 0 2.6rem 3rem rgba(0, 0, 0, .05);
  box-shadow: 0 2.6rem 3rem rgba(0, 0, 0, .05);
}

.basic-panel.info .basic-panel__content--title {
  font-size: 28px;
  line-height: 3.6rem;
  margin-bottom: 1.6rem;
}

.basic-panel.info .basic-panel__content--title {
  font-size: 38px;
  line-height: 2.5rem;
}

.link-primary {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  font-size: 20px;
  font-weight: 600;
  line-height: 2.8rem;
  color: #000;
  padding-right: 3rem;
}

.link-primary, .link-primary:hover {
  text-decoration: none;
  color: #000;
}

/** blog single **/ 

.page-title-noimg__wrapper {
  background-color: #e5eaef;
  padding: 2.4rem 0 3.8rem;
  text-align: center;
}

.page-title-noimg__content--title {
  font-size: 36px;
  line-height: 4.8rem;
  margin-top: .1rem;
  margin-bottom: 0;
}

.page-title-noimg__content--title {
  font-size: 36px;
  line-height: 4.8rem;
  margin-top: .1rem;
  margin-bottom: 0;
}

.page-title-noimg__content--desc {
  font-weight: 300;
  font-size: 24px;
  line-height: 3.4rem;
  margin-top: 1.4rem;
  color: #000;
}




/** statements **/ 

.search-page__filter--wrapper {
  max-width: 100%;
  width: 100%;
}

.search-page__filter--inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-width: 100%;
  width: 100%;
}

.search-page__filter--label {
  font-size: 18px;
  line-height: 2.8rem;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  font-weight: 700;
  margin-right: 2rem;
}

.search-page__filter--label span {
  margin-left: .5rem;
  font-weight: 400;
}

.search-page__filter--items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
  padding: 0;
  margin: 0;
  list-style-type: none;
  z-index: 2;
}

.search-page__filter--items .item {
  padding: 1.6rem;
  margin-right: 1.2rem;
  font-style: italic;
  font-size: 15px;
  font-weight: 600;
  line-height: 1.6rem;
  color: #68737a;
  background: #fff;
  border-radius: 5rem;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  cursor: pointer;
}


.search-results {
  position: relative;
  padding-top: 2rem;
  padding-bottom: 2rem;
  color: #000;
}

.search-results__content--sup {
  font-weight: 600;
  font-size: 12px;
  line-height: 1.7rem;
}

.search-results__content--title {
  margin-top: .8rem;
  font-weight: 700;
  font-size: 20px;
  line-height: 2.8rem;
  color: #000;
}

.search-results__content--desc {
  margin-top: 1.6rem;
  font-weight: 400;
  font-size: 16px;
  line-height: 2.4rem;
}

.search-results__btn .btn-arrow {
  margin-left: auto;
}

.search-results__img {
  position: relative;
  border-radius: .8rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  max-width: 240px;
  height: 13.4rem;
  overflow: hidden;
}

.search-results__img.no-img {
  background-color: #e5eaef;
}


.search-results__img .icon {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 55px;
}


.search-results__content--sup .color-red {
  margin-right: 2rem;
}

.color-red {
  color: #ed1b2e;
}

/** claims **/

.agentCard{
  padding-top: 27% !important;
  padding-bottom: 27% !important;
}


@media only screen and (min-width: 992px) {
  .prulife::before {
      bottom: 0;       
      /* min-height: 50.6rem; */
  }

  .basic-panel.promotion .basic-panel__wrapper {
    margin-top: 6.4rem;
}

.container, [class*=' col'], [class^=col-] {
  padding-left: 17px !important;
  padding-right: 17px !important;
}

.basic-panel.promotion .basic-panel__outer {
  padding-right: 1.7rem;
  width: 50%;
}

.basic-panel.promotion .basic-panel__outer {
  position: relative;
  width: 100%;
  padding-right: 2.6rem;
}

.basic-panel.promotion .basic-panel__outer::before {
  bottom: 0;
  height: calc(100% + 6.4rem);
  min-height: 50.6rem;
}


.basic-panel.promotion .basic-panel__outer1::before {
  bottom: 0;
  height: calc(100% + 6.4rem);
  min-height: 50.6rem;
}

.component-rich-text__wrapper {
  padding-top: 3rem;
  margin-top: 3rem;
}

.component-rich-text .bk-blue-gray {
  max-width: 35.6rem;
  left: 40rem;
  bottom: 4rem;
}


.component-rich-text .bk-blue-gray-intro {
  max-width: 42.6rem;
  left:-2rem;
  bottom: 4rem;
}

.component-rich-text .bk-blue-gray1 {
  max-width: 42.6rem;
  left:-2rem;
  bottom: 4rem;
}

.component-rich-text .bk-blue-gray2 {
  max-width: 35.6rem;
  left:-2rem;
  bottom: 4rem;
}


.component-rich-text .bk-blue-gray3 {
  max-width: 33.6rem;
  left:-2rem;
  bottom: -3rem;
}


.component-rich-text .bk-blue-gray4 {
  max-width: 35.6rem;
  left: 40rem;
  bottom: 0rem;
}


.container-rich-text {
  padding: 3.2rem 0;
}

.container-rich-text .rich-text {
  padding: 0 4rem;
}
h2 {
  font-size: 38px;
  line-height: 48px;
}

.container, .container-md, .container-sm {
  max-width: 1200px  !important;
}

.container, .container-fluid, .container-md, .container-sm {
  width: 100% !important;
  padding-right: 17px !important;
  padding-left: 17px !important;
  margin-right: auto !important;
  margin-left: auto !important;
}

.card-static__content--btn.btn-arrow {
  right: 2.6rem;
}

.card-static__content--btn.btn-arrow1 {
  right: 8.6rem;
}

.basic-panel__content--title {
  font-size: 55px;
  line-height: 4rem;
}

.widthOverride{
  max-width: 1320px !important;
}

.filter-tab-item {
  padding:0.4rem 1.5rem !important;
  margin-bottom: 1.2rem !important;
  border-radius: 4rem !important;
}

.page-title__page-wrapper {
  padding-top: 5.5rem;
}

.page-title__content-outer {
  padding-top: 0;
  width: 100%;
  padding-bottom: 0;
}

.page-title__content {
  padding-top: 3rem;
}

.page-title__content--name {
  font-size: 48px;
  line-height: 60px;
}

.page-title__content--desc {
  margin-top: 2rem;
}

.page-title__img-outer {
  overflow: hidden;
}

.page-title__img-outer1 {
  overflow: hidden;
}

.page-title__img-inner {
  padding-bottom: 0%;
}

.page-title__img-outer .page-title--img {
  position: relative;
}

.accordion-comp {
  margin-bottom: 2rem;
}

.accordion-comp-header {
  padding: 0.4rem 0rem 0.4rem 1rem;
}

.accordion-comp-title {
  font-size: 1.4rem;
  line-height: 1.8rem;
  font-weight: 700;
  color: #000;
}

.contact-form {
  padding-top: 11.4rem;
}

.title-form {
  margin-bottom: 1rem;
  font-size: 48px;
  line-height: 3.5rem;
}

.group-field__title {
  font-size: 20px;
}

.section-radio-checkbox {
  margin-bottom: 4rem;
}


.input-text-field input[type=email] {
  font-size: 20px;
  padding-left: 2.4rem;
  padding-right: 2.4rem;
}

.input-text-field input[type=number]{
  font-size: 20px;
  padding-left: 2.4rem;
  padding-right: 2.4rem;
}

.input-text-field input[type=text] {
  font-size: 20px;
  padding-left: 2.4rem;
  padding-right: 2.4rem;
}

.input-text-field .field-placeholder {
  font-size: 1rem;
  padding: 0 2.4rem;
}


.standardPadding{
  padding: 3% !important
}

.paddingOff{
  padding:0px !important;
}

.noBackground{
  background: transparent !important;
  box-shadow: none !important;
}

.circlePadding{
  display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  align-items: center;
  width: 166px !important;
  height: 166px;
  background-color: #fff;
  border-radius: 50%;
  margin: 0 auto;
  transition: box-shadow .2s ease-in-out, -webkit-box-shadow .2s ease-in-out;
}

.basic-panel.info .basic-panel__wrapper::before {
  content: "";
  width: 39.507%;
  max-width: 52.6rem;
  height: 90.8%;
  max-height: 62.4rem;
  bottom: 0;
}

.basic-panel.info .basic-panel__outer--img {
  padding-bottom: 47.62%;
  margin-bottom: 13.2rem;
}

.basic-panel.info .basic-panel__content {
  position: absolute;
  bottom: 6.8rem;
  left: 4.7rem;
}

.basic-panel.info .basic-panel__content--wrapper {
  padding: 4rem 3.3rem;
  border-radius: .8rem;
}

.page-title-noimg__wrapper {
  padding: 7rem 0 6rem;
}

.page-title-noimg__content--title {
  font-size: 48px;
  line-height: 3.2rem;
}

.page-title-noimg__content--desc {
  margin-top: 1.6rem;
}

.component-rich-text .bk-blue-gray5 {
  max-width: 20.6rem;
  left: -2rem;
  bottom: -3rem;
}

.search-page__filter {
  margin-top: 6rem;
}

.search-page__filter--label {
  padding-top: 1rem;
  font-size: 20px;
}

.search-page__filter--items {
  width: 100%;
  margin-top: 0;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.search-page__filter--items .item {
  padding: 1.6rem 2rem;
  margin-bottom: 1.2rem;
}

.search-results__content--sup {
  font-size: 14px;
  line-height: 2.4rem;
}

.search-results__content--title {
  font-size: 24px;
  line-height: 3.2rem;
}

.search-results__content--desc {
  font-size: 18px;
  line-height: 2.8rem;
}


.scrolled {
  margin-top: -9.2rem;
}

}

@media (max-width: 991px) {
  .dissapear {
    display: none;
  }

  .product-owl .owl-stage-outer {
   
    height: 59vh;
}

  .image {
    width: 150px;
  }

.page-title__img-outer::before {
    top: 51%;
    left: -15px !important;
}

.page-title__img-outer1::before {
  top: 69%;
  left: -15px !important;
}


.page-title--img{
  position:relative
}


.inlineMargin{
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 10% !important ;
}

.basic-panel__content--title {
  line-height: 2.8rem;
}

.component-rich-text .bk-blue-gray1, .component-rich-text .bk-blue-gray, .component-rich-text .bk-blue-gray3, .component-rich-text .bk-blue-gray4 {
 display: none
}


.halfWidth{
  width: 90% !important;
}

.border-radius-card1{
  border-top-left-radius: 0.8rem;
  border-top-right-radius: 0.8rem;
  border-bottom-left-radius: 0rem;
}

.landingCarousel{
  height: 74vh !important;
}

.landingCarouselCaption{
  background-color: white;
  padding: 3.2rem 1rem;
  border-radius: 0.8rem;
  width:85%;
  color: black !important;
  text-align: left !important;
  bottom: -13% !important;
  z-index: 530;
  box-shadow: 0 2.6rem 3rem rgba(0,0,0,.05)
}

.bannerText2 {
  font-size: 31px;
  font-weight: 700;
}

.filter-tab-item {
  padding: 0.2rem 0.3rem !important;
}

.carouselCard{
  width:18rem !important
}

.blog-owl .owl-stage-outer {
  position: relative;
  height: 59vh ;
}

.basic-panel {
   overflow: visible
}

.component-rich-text .bk-blue-gray-intro {
  width: 78% !important;
  left: 50% !important
}


.clearSpaceBottom {
  margin-bottom: 62%;
}

.accordion-comp-header {
  padding: 2rem 0.4rem 2rem 0.4rem;
}

.page-title__content-outer {
  padding-top: 0.6rem;
  padding-bottom: 5rem;
}

.page-title{
  padding-top:28%;
}
 
}